import React from "react";
import styled from "styled-components";
// import Img from "gatsby-image";
import { graphql } from "gatsby";
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";

const axios = require("axios");

// const Map = styled(Img)`
//   width: 100%;
// `;

class Contact extends React.Component {
  state = {
    nom: "",
    prenom: "",
    telephone: "",
    email: "",
    message: "",
    messageFormulaire: ""
  };

  handleInput = type => e => {
    this.setState({ [type]: e.target.value });
  };

  handleSubmit = e => {
    const { nom, prenom, telephone, email, message } = this.state;
    axios
      .post(
        "https://hg2n74cw9f.execute-api.eu-west-1.amazonaws.com/dev/contact-form",
        {
          nom,
          prenom,
          telephone,
          email,
          message
        }
      )
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({
      messageFormulaire: "Votre message a bien été envoyé",
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
      message: ""
    });
  };

  render() {
    const { nom, prenom, telephone, email, message } = this.state;
    const messageFormulaire = this.state.messageFormulaire || null;

    let isDisable = true;
    if (!nom || !prenom || !telephone || !email || !message) {
      isDisable = true;
    } else {
      isDisable = false;
    }

    return (
      <Layout>
        <SEO
          title="Contactez-nous pour votre site internet"
          description="Besoin d'un site internet ou d'une application web, on serait ravie d'échanger avec vous !"
        />
        <Banner
          title="Contact"
          baseline="Ne soyez pas timide, on adore échanger !"
          imageBanner={this.props.data.imageBanner.childImageSharp.fluid}
        />
        <section id="contact1" className="contact contact-1">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="heading heading-2 heading-9">
                  <h2 className="mb-0">Nos coordonnées</h2>
                </div>
                <div className="contact-info">
                  <div className="contact-bio">
                    N'hésitez pas à nous contacter si vous avez des questions ou
                    besoin de nos services&nbsp;!
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="contact-panel mb-30">
                        <div className="contact-icon">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="contact-content">
                          <h6>Adresse :</h6>
                          <a
                            href="https://goo.gl/maps/WWCadfSUKYR2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            28 Boulevard Benoni Goullin, 44200 Nantes, France
                          </a>
                          {/* <a href="#">Voir sur Google Map</a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="contact-panel mb-30">
                        <div className="contact-icon">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="contact-content">
                          <h6>Email :</h6>
                          <Obfuscate email="vincent@azerty-studio.fr" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="contact-panel">
                        <div className="contact-icon">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="contact-content">
                          <h6>Téléphone :</h6>
                          <Obfuscate tel="0272207121" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="contact-form bg-gray">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-heading">
                        Envoyez-nous un message
                      </div>
                    </div>
                  </div>
                  <form
                    id="contact-form"
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <input type="hidden" name="form-name" value="contact" />
                        <input
                          type="text"
                          className="form-control mb-30"
                          name="contact-name"
                          id="name"
                          placeholder="Nom *"
                          required
                          onChange={this.handleInput("nom")}
                          value={nom}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control mb-30"
                          name="contact-firstname"
                          id="firstname"
                          placeholder="Prénom *"
                          required
                          onChange={this.handleInput("prenom")}
                          value={prenom}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control mb-30"
                          name="contact-phone"
                          id="phone"
                          placeholder="Téléphone *"
                          required
                          onChange={this.handleInput("telephone")}
                          value={telephone}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          className="form-control mb-30"
                          name="contact-email"
                          id="email"
                          placeholder="Email *"
                          required
                          onChange={this.handleInput("email")}
                          value={email}
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          className="form-control mb-30"
                          name="contact-message"
                          id="message"
                          rows="2"
                          placeholder="Votre message *"
                          required
                          onChange={this.handleInput("message")}
                          value={message}
                        />
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          id="submit-message"
                          className="btn btn-block btn-ternary btn-hover"
                          onClick={this.handleSubmit}
                          disabled={isDisable}
                        >
                          Envoyer
                        </button>
                        {messageFormulaire && messageFormulaire.length > 0 ? (
                          <div className=" message-form text-center">
                            <i className="fas fa-star" />
                            <span className="message-form">
                              {messageFormulaire}
                            </span>
                            <i className="fas fa-star" />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Map
          fluid={this.props.data.imageMap.childImageSharp.fluid}
          alt="Google map"
        /> */}
      </Layout>
    );
  }
}

export default Contact;

export const pageQuery = graphql`
  query {
    imageBanner: file(relativePath: { eq: "background/bannerContact.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    # imageMap: file(relativePath: { eq: "background/map.png" }) {
    #   childImageSharp {
    #     fluid(quality: 100) {
    #       ...GatsbyImageSharpFluid_withWebp
    #     }
    #   }
    # }
  }
`;
